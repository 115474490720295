<template>
  <div class="overlay">
    <header>
      <div class="logo">JG</div>
      <div class="logo glitch">JG</div>
      <div class="logo glitch">JG</div>
      <div class="stats" :style="statFontSize">
        <div class="details">
          <p>name: Jeremy Granadillo</p>
          <p>location: portfolio</p>
        </div>
        <!-- <ul>
          <li>vue</li>
          <li>web-components</li>
          <li>golang</li>
          <li>ux</li>
        </ul> -->
        <p class="proficiencies">proficiencies: vue; web-components; golang; ux;</p>
        <button class="contact-button" @click="contactForm">submit contact</button>
      </div>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        v-show="contactFormShow"
        :style="statFontSize"
      >
        <input type="hidden" name="form-name" value="contact">
        <label>
          contact//identifier:
          <input type="text" name="name" placeholder="name / handle" />
        </label>
        <label>
          contact//reply-destination:
          <input type="text" name="replycontact" placeholder="email / social" />
        </label>
        <label for="message">
          contact//message:
        </label>
        <textarea name="message" id="message" cols="54" rows="8"></textarea>
        <button type="submit">submit</button>
      </form>
      <!-- proficiencies, top right, like stat line, or part of a stat box: "proficiencies: web-components,(or ';' ?) vue, javascript, golang, ux"
      to fill a lot of negative empty space on top of text-display. -->
    </header>

    <h1 class="title" :animation-state="animationState" v-if="activeSlide">
      {{activeSlide.title}}
    </h1>

    <div class="frame grid-container">
      <!-- <div class="left panel"></div>
      <div class="top panel"></div>
      <div class="bottom panel"></div>
      <div class="right panel"></div> -->
      <div class="filler"></div>
      <div class="border inner"></div>
      <div class="border outer"></div>
    </div>

    <text-display
      :animation-state="animationState"
      :content="activeContent"
      :containerHeight="textDisplayHeight"
      :mini="textDisplayMini"
      @fontSizeChanged="textDisplayFontSize"/>
    <!-- <text-display class="bit-font-1" :animation-state="animationState" :content="activeContent" /> -->
    <!-- <text-display class="bit-font-2" :animation-state="animationState" :content="activeContent" /> -->
    <!-- <text-display class="bit-font-3" :animation-state="animationState" :content="activeContent" /> -->
    <!-- <text-display class="bit-font-4" :animation-state="animationState" :content="activeContent" /> -->
    <!-- <text-display class="bit-font-5" :animation-state="animationState" :content="activeContent" /> -->
    <!-- <text-display class="bit-font-6" :animation-state="animationState" :content="activeContent" /> -->
  </div>
  <data-slide v-for="slide in slides" :data="slide" :key="slide.id"/>
  <section class="grid-container"><div class="filler"></div></section>
</template>

<script>
import DataSlide from './components/DataSlide.vue'
import TextDisplay from './components/TextDisplay.vue'

import _debounce from 'lodash/debounce'
import _throttle from 'lodash/throttle'

// TODO: add bounce or drag effect when scrolling in the wrong direction, show it like static, and that's dragged in the finger's direction.
// conveys that what they are doing is being received, but not working, the system is resisting.
export default {
  name: 'App',
  components: {
    DataSlide,
    TextDisplay
  },
  data () {
    return {
      activeSlide: null,
      animationState: null,
      textDisplayHeight: null,
      textDisplayMini: false,
      contactFormShow: false,
      statFontSize: {},
      slides: [
        {
          id: 'reefdrone',
          title: 'Reef Monitoring Drone',
          img: 'Drone.png',
          highlights: [
            { x: 2.5, y: 0.9, content: 'Low energy submarine hull, with several sensors' },
            { x: 3.7, y: 1.4, content: 'Identify coral via machine learning' },
            { x: 4.1, y: 4.8, content: 'Display coral locations, grouped by map zoom level' }
          ],
          content: [
            'I built this underwater glider drone as part of my graduation project while interning at Brenchie\'s Lab.',
            'This project would provide a way to map and then monitor the coral reefs and sea floor over time.',
            'It was one of a three part system of capture device (the glider drone), data analysis (machine learning on images) and data presentation (interactive website).'
          ],
          active: false
        }, {
          id: 'swipetable',
          title: 'SwipeTable',
          img: 'swipetable-grayscale.gif',
          video: 'swipetable-grayscale-cropped.mp4',
          highlights: [
            { x: 2.2, y: 0.9, content: 'Lightweight library to easily browse tabular data' },
            { x: 3.8, y: 1.55, content: 'Scrolled headers maintain position while browsing' },
            { x: 4.7, y: 4.6, content: 'Built for touch interaction' }
          ],
          content: [
            'My main assignment during a 6 month internship at Lunatech. Use case: there needed to be a way to show tabular data that can be easily browsed on a device(tablet or mobile).',
            'It is a solution consisting of a Javascript library and some CSS.',
            'You can see it in action at the <a target="_blank" href="http://blind238.github.io/SwipeTable/">SwipeTable page</a>, along with features and usage. <a target="_blank" href="https://www.dropbox.com/s/p7qvql6w5arixtw/Internship%20Report%20Final.docx?dl=0">Internship Report</a>.'
          ],
          active: false
        }, {
          id: 'timeline',
          title: 'Communication Timeline',
          img: 'Tu4yrA4JFn-cropped.gif',
          video: 'Tu4yrA4JFn-cropped.mp4',
          highlights: [
            { x: 3.2, y: 1.1, content: 'Show timeline of client communications' },
            { x: 3.6, y: 2.4, content: 'Group entries into clusters' },
            { x: 4.2, y: 4.3, content: 'In browser batch processing' }
          ],
          content: [
            'During my time at AskFast I was developing demo pages for new features, building new features, making performance optimizations and maintaining/upgrading the front end architecture.',
            'One of the more important features I built was an adaptive timeline for customer communications. Askfast clients wanted an overview of all touch points using different forms of communication.'
          ],
          active: false
        }
      ]
    }
  },
  computed: {
    activeContent () {
      return (this.activeSlide && this.activeSlide.content)
        ? this.activeSlide.content
        : []
    }
  },
  methods: {
    registerObserver () {
      const frameRect = document.querySelector('.frame').getBoundingClientRect()

      // rootMargin MAY NOT apply on VIEWPORT or BODY, test if container needed
      // let containerRect = document.documentElement.getBoundingClientRect()
      // let containerRect = document.body.getBoundingClientRect()
      const containerRect = document.querySelector('#app').getBoundingClientRect()

      // negative margin = margin inward, positive margin = margin outward
      const observerRootMargin = `-${frameRect.y}px
                                  -${containerRect.width - (frameRect.x + frameRect.width)}px
                                  -${containerRect.height - (frameRect.y + frameRect.height)}px
                                  -${frameRect.x}px`

      const observerOptions = {
        // root: document.documentElement,
        root: document.querySelector('#app'),
        rootMargin: observerRootMargin,
        // threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
        threshold: [0.5, 0.9, 1.0]
      }

      this.observer = new IntersectionObserver(this.observerCallback, observerOptions)

      document.querySelectorAll('section').forEach(element => {
        this.observer.observe(element)
      })
    },
    observerCallback (entries, observer) {
      entries.forEach(entry => {
        if (entry.target.id === this.activeSlide.id) {
          if (entry.intersectionRatio < 0.5) {
            this.animationState = 'hidden'
          } else if (entry.intersectionRatio <= 1) {
            const id = entry.target.id
            this.activeSlide.active = false
            this.activeSlide = this.slides.filter(slide => slide.id === id)[0]
            this.activeSlide.active = true
            if (entry.intersectionRatio > 0.9) {
              this.animationState = 'idle'
            } else {
              this.animationState = 'animating'
            }
          }
        } else if (this.animationState === 'hidden') {
          if (entry.intersectionRatio > 0.5) {
            // for last filler section
            if (entry.target.id === '') { return }

            const id = entry.target.id
            this.activeSlide.active = false
            this.activeSlide = this.slides.filter(slide => slide.id === id)[0]
            this.activeSlide.active = true
            if (entry.intersectionRatio > 0.9) {
              this.animationState = 'idle'
            } else {
              this.animationState = 'animating'
            }
          }
        }
      })
    },
    updateTextDisplayHeight () {
      const appRect = document.querySelector('#app').getBoundingClientRect()
      const ratio = appRect.width / appRect.height
      if (ratio <= 1) {
        this.textDisplayMini = true
      } else {
        this.textDisplayMini = false
      }
      this.textDisplayHeight = document.querySelector('.text-display').getBoundingClientRect().height
    },
    onWindowResize () {
      this.debouncedResize()
      this.throttledResize()
    },
    contactForm () {
      // lock the height cause fucking keyboard
      if (this.contactFormShow) {
        document.querySelector('html').style.height = null
      } else {
        const height = document.querySelector('#app').getBoundingClientRect().height
        document.querySelector('html').style.height = height + 'px'
      }
      this.contactFormShow = !this.contactFormShow
    },
    textDisplayFontSize (fontSize) {
      if (this.contactFormShow) { return }
      this.statFontSize = { fontSize: fontSize * 0.834 + 'px' }
    }
  },
  created () {
    this.debouncedResize = _debounce(() => {
      this.observer.disconnect()
      this.observer = null
      this.registerObserver()
    }, 200)
    this.throttledResize = _throttle(this.updateTextDisplayHeight, 200)
  },
  mounted () {
    this.activeSlide = this.slides[0]
    window.addEventListener('resize', this.onWindowResize)
    this.registerObserver()
    this.updateTextDisplayHeight()
  },
  beforeUnmounted () {
    window.removeEventListener('resize', this.onWindowResize)
  }
}
</script>

<style lang="scss">
@import "./scss/_colors.scss";
@import "./scss/_variables.scss";

@font-face {
  font-family: GlitchFont;
  src: url("/fonts/SystemGlitch-Regular.otf");
}
@font-face {
  font-family: BitFont1;
  src: url("/fonts/Monoid-Regular.ttf");
}
@font-face {
  font-family: BitFont2;
  src: url("/fonts/Px437/Px437_IBM_CGAthin-2y.ttf");
}
@font-face {
  font-family: BitFont3;
  // src: url("./assets/fonts/Px437/Px437_IBM_VGA9.ttf");
  src: url("/fonts/BigBlue_Terminal/BigBlue_Terminal_437TT.ttf");
}

html {
  box-sizing: border-box;
  font-family: BitFont2, sans-serif;
  height: 100%;
  overflow-y: hidden;
  background-color: black;
}
*, *::before, *::after {
  box-sizing: inherit;
}

h1 {
  font-size: 1.424rem;
}

h2 {
  font-size: 1.266rem;
}

h3 {
  font-size: 1.125rem;
}

// p {
//   line-height: 1.3;
// }

body {
  margin: 0;
  padding: 0;
  height: 100%;
  // height: 99.9%;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // scroll-padding-top: $firstMargin;
}

#app {
  margin: 0;
  padding: 0;
  height: 100%;
  // height: 99.9%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scroll-padding-top: $firstMargin;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
}

// From Normalize.css
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input { /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select { /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*------------------------------------*/

a:link {
  color: darken($accent, 6%);
}
a:visited {
  color: desaturate(darken($accent, 18%), 38%);
}

header {
  width: 100vw;

  .logo {
    position: absolute;
    margin: 1.2rem 0.8rem;
    padding: 0 0.3rem;
    background-color: black;
    font-family: GlitchFont;
    letter-spacing: -0.13em;
    font-size: 3.4rem;
    line-height: 1.2;
    color: $lightest;

    &::first-letter {
      vertical-align: top;
      line-height: 1.06;
    }

    &.glitch {
      // animation: logo-glitch 1.5s linear infinite;
      animation: logo-glitch 2.25s linear infinite;
    }

    &:nth-child(2) {
      left: 0.06em;
      animation-delay: 0.1s;
    }

    &:nth-child(3) {
      left: -0.06em;
    }

  }

  .stats, form {
    font-size: calc(0.7rem + 0.25vw);
  }

  .stats > *, form > * {
    font-size: 0.95em;
  }

  .stats {
    color: $lighter;
    // border: 2px solid $light;
    box-shadow: inset 0px 0px 0px 0.05em $light, 0px 0px 0px 0.05em $light;
    margin: 0.75em 2em;
    position: absolute;
    right: 0;
    // display: flex;
    // flex-wrap: wrap;
    // width: 33rem;

    display: grid;
    grid-template-areas:
    "details         details"
    "proficiencies contact-button";

    .details {
      display: flex;
      grid-area: details;
    }
    .proficiencies {
      grid-area: proficiencies;
    }

    .contact-button {
      grid-area: contact-button;
    }

    p {
      margin: -0.05em;
      // margin: 0;
      padding: 0.25em;
      border: 0.1em solid $light;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline;

        &::after {
          content: '; ';
        }
      }
    }

    button {
      background-color: $darkest;
      color: $lighter;

      &:focus {
        outline: 0.1em dashed $accent;
      }
    }
  }

  form {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 4rem;
    top: 6rem;
    padding: 0.25rem;
    z-index: 1;
    color: $lightest;
    background-color: $darkest;
    border: 2px solid $light;

    label {
      display: flex;
      justify-content: space-between;
      margin: 0.25rem;
    }
    input, textarea {
      color: $lighter;
      background-color: black;
      padding: 0 0.5rem
    }
    input {
      margin-left: 0.25rem;
      width: 44%;
    }
    textarea {
      align-self: flex-end;
    }
  }
}

@keyframes logo-glitch {
  // 0%, 12.999%,
  0%, 8.666%,
  // 23%, 26.999%,
  15.333%, 17.999%,
  // 49%, 53.999%,
  32.667%, 35.999%,
  // 62%, 69.999%,
  41.333%, 56.666%,
  // 90%, 100%{
  70%, 100%{
    clip-path: inset(10% 0 90% 0);
  }
  // 13%, 22.999% {
  8.667%, 15.332% {
    clip-path: inset(9% 0 87% 0);
  }
  // 27%, 35.999%{
  18%, 23.999%{
    clip-path: inset(36% 0 59% 0);
  }
  // 36%, 48.999% {
  24%, 32.666% {
    clip-path: inset(43% 0 52% 0);
  }
  // 54%, 61.999% {
  36%, 41.332% {
    clip-path: inset(28% 0 69% 0);
  }
  // 70% {
  // 46.667% {
  56.667% {
    clip-path: inset(97% 0 0 0);
  }
  // 89.999% {
  // 59.999% {
  69.999% {
    clip-path: inset(50% 0 49% 0);
  }
}

.overlay {
  position: absolute;
  height: 100%;
}

.title {
  position: absolute;
  color: $lighter;
  font-family: BitFont3;
  font-size: 2.1rem;
  // margin-left: 8rem;
  margin-left: 6.65vw;
  // margin-top: $firstMargin - ($sectionMargin * 0.75);
  margin-top: calc(#{$firstMargin} - (#{$sectionMargin} * 0.75));
  width: 40rem;
  transform: translate3d(0, 0, 0);
}

.text-display {
  position: absolute;
  top: 48.1%;
  transform: translateY(-50%);
  left: 51vw;
  // 4:3
  // width: 48rem;
  // height: 36rem;
  width: 38vw;
  height: 28.5vw;

  pointer-events: none;
}

.frame {
  .top {
    grid-area: top;
  }
  .left {
    grid-area: left;
  }
  .right {
    grid-area: right;
  }
  .bottom {
    grid-area: bottom;
  }

  .panel {
    background-color: $lightest ;
    // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);

    // @supports (filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2))) {
    //   box-shadow: none;
    // }
  }

  .border {
    &.inner {
      border: 3px dashed $light;
      grid-area: middle;
      margin: -3px;
    }

    &.outer {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid $dark;
    }
  }

  z-index: 1;

  position: fixed;
  left: 0;
  right: 0;
  margin-top: $firstMargin;
  /* fixes scrolling being cancelled when cursor is on overlay*/
  pointer-events: none;
  // @supports (filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2))) {
  //   // filter: drop-shadow(0px 5px 4px rgba(0,0,0,0.2));
  //   filter: drop-shadow(0px 2px 1.5px rgba(0,0,0,0.5));
  //   // filter: drop-shadow(0px 2px 1.5px rgba(0,0,0,0.5)) drop-shadow(0px 5px 4px rgba(0,0,0,0.2));
  // }
}

section:first-of-type {
  margin-top: $firstMargin;
}

.filler {
  grid-area: middle;
  padding-top: $ratio;
}

header {
  position: absolute;
  height: 4rem;
}

section {
  position: relative;
  margin-top: $sectionMargin;
  scroll-snap-align: start;
  scroll-snap-stop: always; // make chrome behave
  // box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.6);

  // @supports (filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2))) {
  //   box-shadow: none;
  //   filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.7));
  // }

  p {
    display: none;
  }
}

$accent-side: 1.8em;
$accent-margin: 0.65em;

.frame::after {
  content: "";
  width: $accent-side;
  height: $accent-side;
  top: $accent-margin;
  right: $accent-margin;
  position: absolute;
  border-top: $accent-side/2 solid $accent;
  border-right: $accent-side/2 solid $accent;
  border-bottom: $accent-side/2 solid transparent;
  border-left: $accent-side/2 solid transparent;
}

.grid-container {
  display: grid;
  grid-template-areas:
  "left   top     right"
  "left   middle  right"
  "bottom bottom  right";

  grid-template-columns: 1.25em 1fr 2em;
  grid-template-rows:    2em 1fr 1.25em;

  max-width: 48rem;
  width: 40.1vw;
  // margin-left: 7rem;
  margin-left: 5.85vw;
}

@media screen and (min-width : 1921px) {
  .text-display {
    width: 32vw;
    height: 24vw;
  }

  .grid-container {
    max-width: unset;
    width: 31vw;
    margin-left:8vw;
  }

  .title {
    margin-left:8.7vw;
  }

  header {
    .logo {
      font-size: 3.8rem;
    }

    .stats {
      margin-top: 0.6em;
    }
  }

}

@media screen and (max-width: 1000px) {
// @media (max-aspect-ratio: 1/1) {
  $frameWidth: 72;
  $firstMarginLeft: 1.08rem;

  #app {
    display: flex;
    align-items: flex-start;
    scroll-snap-type: x mandatory;
    overflow-y: unset;
    overflow-x: scroll;
    scroll-padding-top: unset;
    scroll-padding-left: $firstMarginLeft;
    width: 100%;
  }

  .overlay {
    pointer-events: none;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  header {
    pointer-events: all;

    .logo {
      margin: 1rem 0.35rem;
      font-size: 2.24rem;

      &:nth-child(2) {
        left: 0.15rem;
      }

      &:nth-child(3) {
        left: -0.15rem;
      }
    }

    .stats, form {
      font-size: calc(0.31rem + 0.8vw);
    }

    .stats {
      margin: 0.5rem;
      right: 0;
      // display: flex;
      // flex-wrap: wrap;
      // width: 33rem;

      grid-template-areas:
      "details"
      "proficiencies"
      "contact-button";

      .details {
        display: flex;
        grid-area: details;
        p:first-child {
          flex: 1;
        }
      }
      .proficiencies {
        grid-area: proficiencies;
      }

      .contact-button {
        grid-area: contact-button;
      }

      p {
        margin: -0.5px;
        padding: 0.2rem;
        border: 1px solid $light;
      }

      button {
        background-color: $darkest;
        color: $lighter;

        &:focus {
          outline: 2px dashed $accent;
        }
      }
    }

    form {
      right: 0.5em;
      top: 5.9em;
      padding: 0.25em;
      z-index: 2;

      label {
        margin: 0.16em;
      }
      input, textarea {
        padding: 0.05em 0.5em;
      }
      input {
        width: 48%;
      }
    }
  }

  .title {
    font-size: 1.02rem;
    margin-left: 1.03rem;
    width: 20rem;
    margin-top: 3.8rem;
    position: unset;
  }

  .frame, section:first-of-type, section {
    margin-top: 5.4rem;
  }

  $accent-side-alt: 1.2rem;
  $accent-margin-alt: 0.45rem;

  .frame::after {
    width: $accent-side-alt;
    height: $accent-side-alt;
    top: $accent-margin-alt;
    right: $accent-margin-alt;
    border-top: $accent-side-alt/2 solid $accent;
    border-right: $accent-side-alt/2 solid $accent;
    border-bottom: $accent-side-alt/2 solid transparent;
    border-left: $accent-side-alt/2 solid transparent;
  }

  .frame {
    .border {
      &.inner {
        border: 2px dashed $light;
        margin: -2px;
      }
    }
  }

  .grid-container {
    width: 72vw;
    margin-left: unset;
    grid-template-columns: 0.6rem 1fr 1.25rem;
    grid-template-rows:    1.25rem 1fr 0.6rem;
  }

  .frame, section:first-of-type {
    margin-left: $firstMarginLeft;
  }

  .text-display {
    left: 0;
    right: 0;
    // top: calc(45% + 39vw);
    // transform: translateY(-50%);
    top: unset;
    transform: unset;
    position: relative;
    margin-top: 47vw;
    margin-left: auto;
    margin-right: auto;
    // width: 90vw;
    width: calc(100vw - #{$firstMarginLeft} * 2 - 4px);
    // height: 67.5vw;
    height: calc((100vw - #{$firstMarginLeft} * 2 - 4px) * 0.75);
  }

  section {
    flex: 0 0 72%;
    margin-left: 4%;
  }
}

@media screen and (max-width: 600px) {
// @media (max-aspect-ratio: 6/7) {
  .title {
    font-size: 1.2rem;
    margin-top: calc(4.6rem + 46vw);
    width: 80vw;
  }

  .text-display {
    margin-top: 0.6rem;
    width: calc(100vw - 1.1rem - 4px);
    height: calc((100vw - 1.1rem - 4px) * 0.75);
  }

  .frame, section:first-of-type {
    margin-left: 0.7rem;
  }

  .grid-container {
    width: 62vw;
  }

  section {
    flex-basis: 62%;
  }
}
</style>
