<template>
  <div class="text-display" :class="animationState">
    <div v-for="n in 5" :key="n" class="text-layer"  :class="n <= 1 ? 'bit-font-1' : 'bit-font-2'" :style="n <= 1 ? fontSize1 : fontSize2">
      <p v-for="(text, index) in content" :key="index" v-html="text" :style="pMargin">
      </p>
    </div>
    <div class="text-layer bit-font-1 instruction" :style="fontSize1">
      <p v-html="instruction"></p>
    </div>
    <div class="text-display-border"></div>
  </div>
</template>

<script>
export default {
  name: 'TextDisplay',
  props: {
    animationState: {
      type: String,
      default: 'idle'
    },
    content: {
      type: Array,
      default () { return [] }
    },
    containerHeight: {
      type: Number,
      default: null
    },
    mini: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // miniMulti: 1.235,
      miniMulti: 1,
      // defined here and in $line-amount
      lines: 16
    }
  },
  computed: {
    fontSize1 () {
      return (this.containerHeight)
        ? { fontSize: this.containerHeight / this.lines / 1.2 * 0.75 * this.multi + 'px' }
        : {}
    },
    fontSize2 () {
      return (this.containerHeight)
        ? { fontSize: this.containerHeight / this.lines / 1.2 * this.multi + 'px' }
        : {}
    },
    pMargin () {
      return (this.containerHeight)
        ? { margin: this.containerHeight / this.lines * this.multi + 'px 0' }
        : {}
    },
    multi () {
      return this.mini ? this.miniMulti : 1
    },
    instruction () {
      return this.mini
        ? '&leftarrow; swipe to navigate &rightarrow;'
        : '&uparrow; scroll to navigate &downarrow;'
    }
  },
  watch: {
    containerHeight: function (newHeight, oldHeight) {
      if (newHeight) {
        this.$emit('fontSizeChanged', newHeight / this.lines / 1.2 * this.multi)
      }
    }
  }
}
</script>

<style>
/* allow to be overwritten*/
.text-display {
  position: relative;
}
</style>

<style lang="scss" scoped>
@import "../scss/_colors.scss";

$root-size: 1.2rem;
$line-height: 1.2;
$line-size: $root-size * $line-height;
// number of lines, each line represents 8 pixel rows
// defined here and in data.lines
$line-amount: 16;
$line-percent: 100% / $line-amount;
$pixel-row-percent: $line-percent / 8;

.idle {
  opacity: 1;

  .text-layer {

    &:nth-child(n+2){
      background-color: black;
    }

    // &:nth-child(2){
    //   left: 0.1rem;
    //   animation: shift-line 2s steps(25, jump-none) infinite;
    // }
    // &:nth-child(2){
    //   opacity: 0.6;
    //   animation: shift-line 1.7s -0.2s linear infinite;
    // }
    &:nth-child(2){
      opacity: 0.8;
      animation: shift-2pixel-row 2.25s -0.2s linear infinite;
    }
    // &:nth-child(2){
    //   opacity: 0.9;
    //   opacity: 1;
    //   animation: shift-2pixel-row 2.25s -0.2s steps(25 * 4, jump-none) infinite;
    // }
    &:nth-child(3){
      left: -0.08em;
      // left: -0.1rem;
      animation: idle-pixel-shift-1 3.5s linear infinite;
    }
    &:nth-child(4){
      left: 0.08em;
      // left: 0.1rem;
      animation: idle-pixel-shift-2 3.5s 100ms linear infinite;
    }
    // &:nth-child(4){
    //   left: -0.12rem;
    //   animation: shift-pixel-row 1.5s steps(10) infinite reverse;
    // }
    &:nth-child(5){
      // left: 0.12rem;
      animation: shift-line 16s steps(16, jump-none) infinite alternate-reverse, flicker 12s linear infinite;
    }
  }
}

.animating {
  opacity: 1;

  .text-layer {

    &:nth-child(n+2){
      background-color: black;
    }

    &:nth-child(2){
      left: 0.08em;
      // left: 0.1rem;
      animation: shift-chunk .4s -0.1s steps(8) 3, invis .1s 1.2s steps(2) forwards;
    }
    &:nth-child(3){
      left: -0.08em;
      // left: -0.1rem;
      animation: shift-chunk .8s steps(9) 2 alternate, invis .1s 1.6s steps(2) forwards;
    }
    &:nth-child(4){
      left: -0.15em;
      // left: -0.12rem;
      animation: shift-chunk 1s steps(10) 1 reverse, invis .1s 1s steps(2) forwards;
    }
    &:nth-child(5){
      left: 0.15em;
      // left: 0.12rem;
      animation: shift-chunk 1.5s steps(4) 1 alternate-reverse, invis .1s 1.5s steps(2) forwards;
    }
  }
}

.hidden {
  opacity: 0;
}

@keyframes shift-pixel-row {
  from {
    clip-path: inset(0 0 #{100% - $pixel-row-percent} 0);
  }
  to {
    clip-path: inset(#{100% - $pixel-row-percent} 0 0 0);
  }
}

@keyframes shift-2pixel-row {
  from {
    clip-path: inset(0 0 #{100% - $pixel-row-percent * 2} 0);
  }
  to {
    clip-path: inset(#{100% - $pixel-row-percent * 2} 0 0 0);
  }
}

@keyframes idle-pixel-shift-1 {
  0%, 19.999%,
  25%, 31.999%,
  40%, 60.999%,
  69%, 76.999%,
  100% {
    clip-path: inset(#{$line-percent * 5} 0 #{$line-percent * ($line-amount - 5)} 0);
    // clip-path: inset(#{$line-percent * 5} 0 #{$line-percent * 20} 0);
  }
  20%, 24.999%{
    clip-path: inset(#{$line-percent * 6 + $pixel-row-percent * 4} 0 #{$line-percent * ($line-amount - 7) + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{$line-percent * 6 + $pixel-row-percent * 4} 0 #{$line-percent * 18 + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{} 0 #{} 0);
  }
  32%, 39.999% {
    clip-path: inset(#{$line-percent * 11 + $pixel-row-percent * 4} 0 #{$line-percent * ($line-amount - 12) + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{$line-percent * 11 + $pixel-row-percent * 4} 0 #{$line-percent * 13 + $pixel-row-percent * 3} 0);
  }
  61%, 68.999% {
    clip-path: inset(#{$line-percent * 2 + $pixel-row-percent * 5} 0 #{$line-percent * ($line-amount - 3) + $pixel-row-percent * 2} 0);
    // clip-path: inset(#{$line-percent * 2 + $pixel-row-percent * 5} 0 #{$line-percent * 22 + $pixel-row-percent * 2} 0);
  }
  77% {
    // clip-path: inset(#{} 0 #{} 0);
  }
}

@keyframes idle-pixel-shift-2 {
  0%, 19.999%,
  25%, 31.999%,
  // 40%, 57.999%,
  // 40%, 62.999%,
  40%, 63.999%,
  // 69%, 76.999%,
  67%, 76.999%,
  100% {
    clip-path: inset(#{$line-percent * 5} 0 #{$line-percent * ($line-amount - 5)} 0);
    // clip-path: inset(#{$line-percent * 5} 0 #{$line-percent * 20} 0);
  }
  20%, 24.999%{
    clip-path: inset(#{$line-percent * 6 + $pixel-row-percent * 4} 0 #{$line-percent * ($line-amount - 7) + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{$line-percent * 6 + $pixel-row-percent * 4} 0 #{$line-percent * 18 + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{} 0 #{} 0);
  }
  32%, 39.999% {
    clip-path: inset(#{$line-percent * 11 + $pixel-row-percent * 4} 0 #{$line-percent * ($line-amount - 12) + $pixel-row-percent * 3} 0);
    // clip-path: inset(#{$line-percent * 11 + $pixel-row-percent * 4} 0 #{$line-percent * 13 + $pixel-row-percent * 3} 0);
  }
  // 58%, 68.999% {
  // 63%, 68.999% {
  64%, 66.999% {
    clip-path: inset(#{$line-percent * 2 + $pixel-row-percent * 5} 0 #{$line-percent * ($line-amount - 3) + $pixel-row-percent * 2} 0);
    // clip-path: inset(#{$line-percent * 2 + $pixel-row-percent * 5} 0 #{$line-percent * 22 + $pixel-row-percent * 2} 0);
  }
  77% {
    // clip-path: inset(#{} 0 #{} 0);
  }
}

@keyframes shift-line {
  from {
    clip-path: inset(0 0 calc(100% - #{$line-percent}) 0);
  }
  to {
    clip-path: inset(calc(100% - #{$line-percent}) 0 0 0);
  }
}

@keyframes shift-chunk {
  from {
    // clip-path: inset(calc(10% - 4rem) 0 90% 0);
    clip-path: inset(#{$line-percent * 0.1} 0 calc(100% - #{$line-percent * 2.5}) 0);
  }
  to {
    // clip-path: inset(calc(95% - 4rem) 0 5% 0);
    clip-path: inset(calc(100% - #{$line-percent * 2.5}) 0 #{$line-percent * 0.1} 0);
  }
}

@keyframes invis {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// use together with shift-line timing to create intermittent line glitch
@keyframes flicker {
  // based on 12 second timing, each second one line
  // 8.333% steps
  0%, 16.666%,
  25%, 33.333%,
  36.334%, 62.333%,
  65.667%, 66.666%,
  74%, 91.666%,
  96%, 100% {
    opacity: 0;
  }

  16.667%, 24.999%,
  34.334%, 36.333%,
  62.334%, 65.666%,
  66.667%, 73.999%,
  91.667%, 95.999% {
    opacity: 1;
  }
}

.text-display {
  font-family: sans-serif;
  color: white;
  font-size: $root-size;
  line-height: $line-height;
  p {
    margin: $line-size 0;
  }
}

.text-display-border {
  position: absolute;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 2px solid $darkest;
  margin: -2px;
  pointer-events: none;
}

// make sure links are clickable
.text-layer :deep(a) {
  pointer-events: auto;
}

.text-layer {
  position: absolute;
  // padding: 0 1.5rem;
  padding: 0 3.125%;
  background-color: black;
  width: 100%;
  height: 100%;

  &:nth-child(n+2){
    background-color: black;
    // opacity: 0.5;
  }

  &.bit-font-1 {
    font-family: BitFont1;
    font-size: $root-size * 0.75;
    // line-height: 1.1875rem;
    // line-height: 1.2rem;
    line-height: 1.60;
    font-feature-settings: "calt" on, "liga" on, "ss01" on, "ss15" on /* mm */;
  }
  &.bit-font-2 {
    font-family: BitFont2;
    // font-size: 0.75rem;
  }
  &.bit-font-3 {
    font-family: BitFont3;
    // font-size: 0.75rem;
  }
  &.bit-font-4 {
    font-family: BitFont4;
    // font-size: 0.75rem;
  }
  &.bit-font-5 {
    font-family: BitFont5;
    // font-size: 0.75rem;
  }
  &.bit-font-6 {
    font-family: BitFont6;
    // font-size: 0.75rem;
  }
}

.instruction {
  display: flex;
  text-align: right;
  background-color: transparent !important;
  opacity: 1 !important;
  flex-direction: column-reverse;
}
</style>
