<template>
  <section :id="data.id" class="data-slide grid-container" :class="{ inactive }">
    <div class="image">
      <video v-if="data.video" autoplay loop muted playsinline :src="require(`../assets/media/${data.video}`)"></video>
      <img v-else :src="require(`../assets/images/${data.img}`)" alt="">
    </div>
  </section>
</template>

<script>
export default {
  name: 'DataSlide',
  props: {
    data: Object
  },
  computed: {
    inactive () {
      return !(this.data && this.data.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/_colors.scss";
@import "../scss/_variables.scss";

img, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

.image {
  position: relative;
  // background-color: $lighter;
  height: 0;
  overflow: hidden;
  grid-area: middle;

  padding-top: $ratio;
}

.inactive {
  opacity: 0.38;
  transition: opacity 0.35s;
}
</style>
